import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PortalApiService} from '../../modules/record/portal-api.service';
import {Identification} from "../../modules/record/identification-models";
import {Facet, StockItem, StockItemSelector} from "../../modules/record/feature-print-models";
import {ConfigService} from "../../modules/base/config.service";
import {AppConfig} from "../../modules/base/app-config";

export interface IdentificationWithDataset {
  identification: Identification;
  dataset: string;
}

@Component({
  selector: 'ae-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent implements OnInit {
  protected identificationId: string | null = null;
  dataset: string | null = null;
  protected matchedStockItemId: string | null = null;
  protected matchedFacetId: string | null = null;
  protected matchedStockItem: StockItem | null = null;
  protected stockItemFacets: Facet[] | null = null;

  @Input() fullView = true;

  @Input() set identificationRecord(value: IdentificationWithDataset | null) {
    this.stockItemFacets = null;
    this.matchedStockItemId = null;
    this.matchedFacetId = null;
    this.identification = value?.identification || null;
    this.dataset = value?.dataset || null;
    this.identificationId = this.identification?.identificationId || null;
    this.getDetail();
  }

  private identificationStream!: Subscription;
  public identification: Identification | null = null;
  public previewUrl: string | null = null;

  private stockItemStream!: Subscription;
  private facetsStream!: Subscription;

  constructor(private recordApi: PortalApiService,
              private route: ActivatedRoute, private config: ConfigService<AppConfig> ) {
  }

  customerName = "";

  ngOnInit(): void {
    this.customerName = this.config.getConfig().customerName;
    this.getDetail();
  }

  getDetail(): void {
    if (this.fullView) {
      this.identification = null;
      this.stockItemFacets = null;
      this.matchedStockItemId = null;
      this.matchedFacetId = null;
      this.identificationId = this.route.snapshot.paramMap.get('identificationId') as string;
      this.dataset = this.route.snapshot.paramMap.get('dataset') as string;
    }

    if (this.identificationStream) {
      this.identificationStream.unsubscribe();
    }

    if(this.identificationId && this.dataset) {
      this.identificationStream = this.recordApi.getIdentification(this.identificationId, this.dataset).subscribe(identification => {
        this.identification = identification;
        this.getPreview();

        this.matchedStockItemId = this.identification?.bestMatchStockItemId || null;
        this.matchedFacetId = this.identification?.bestMatchFacetId || null;
        this.getStockItem();

      });
    }
  }

  getPreview(): void {
    if (this.identification && this.dataset) {
      this.previewUrl = this.recordApi.computePreviewUrl(this.identification.previewImageKey, this.dataset!);
    }
  }

  getStockItem(): void {
    if (this.stockItemStream) {
      this.stockItemStream.unsubscribe();
    }
    if (this.facetsStream) {
      this.facetsStream.unsubscribe();
    }

    if (this.matchedStockItemId && this.dataset) {
      this.stockItemStream = this.recordApi.getStockItem(this.matchedStockItemId, this.dataset).subscribe(stockItem => {
        this.matchedStockItem = stockItem;
      });

      this.facetsStream = this.recordApi.getFacets(this.matchedStockItemId, this.dataset).subscribe(facets => {
        this.stockItemFacets = facets;
      })
    }
  }

}
